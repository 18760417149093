<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div>
          <h4 class="mr-sm-4 header-tablepage">PRODUCT DETAIL</h4>
        </div>
        <div class="mt-3">
          <b-tabs v-model="tabIndex">
            <b-tab title="Information">
              <TabProductDetailPanel
                :form="form"
                :productType="productType"
                :ah1="ah1"
                @searchAH2="searchAH2"
                :ah2="ah2"
                @searchAH3="searchAH3"
                :ah3="ah3"
                @searchAH4="searchAH4"
                :ah4="ah4"
                :isLoadingAh1="isLoadingAh1"
                :isLoadingAh2="isLoadingAh2"
                :isLoadingAh3="isLoadingAh3"
                :isLoadingAh4="isLoadingAh4"
                @getAH4="getAH4"
                :v="$v"
                @searchAH1ByKeyword="getCateProductAh1"
                @searchAH2ByKeyword="searchAH2ByKeyword"
                @searchAH3ByKeyword="searchAH3ByKeyword"
                @searchAH4ByKeyword="searchAH4ByKeyword"
                :id="parseInt(id)"
                @handleSerial="handleSerial"
                :ah_id="{ ah1: ah1_id, ah2: ah2_id, ah3: ah3_id, ah4: ah4_id }"
              />
            </b-tab>
            <b-tab title="Image">
              <TabImageProductDetailPanel
                :form="form"
                :v="$v"
                :id="parseInt(id)"
              />
              <PreviewMultipleImages
                v-on:deleteImages="deleteImagesList"
                v-on:updateImageList="updateImageList"
                :items="form"
                :v="$v.form"
              />
            </b-tab>
            <b-tab title="Inventory">
              <TableInventory ref="inventory" />
            </b-tab>
            <b-tab title="Custom Field">
              <ProductCustomField
                v-if="tabIndex == 3"
                :form="form.custom_field"
                ref="productDetail"
              />
            </b-tab>
            <b-tab
              :disabled="this.id === '0' || form.is_serial == '0'"
              title="Serial"
              :class="this.id == '0' ? 'custom-nav-item' : ''"
            >
              <TabSerial ref="serial" />
            </b-tab>
            <b-tab title="Serial History" v-if="form.is_serial">
              <TabHistory ref="history" />
            </b-tab>
            <b-tab title="Collection">
              <TabCollection ref="collection" @update="updateCollection" />
            </b-tab>
          </b-tabs>
        </div>

        <FooterAction @submit="sendForm" routePath="/product" />
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import TabProductDetailPanel from "@/components/productPromotion/detail/TabProductDetailPanel";
import TabImageProductDetailPanel from "@/components/productPromotion/detail/TabImageProductDetailPanel";
import { required } from "vuelidate/lib/validators";
import ProductCustomField from "../components/ProductCustomField.vue";
function strEndsWith(str, suffix) {
  return str.match(suffix + "$") == suffix;
}
const checkDots = (value) => {
  return !strEndsWith(value, ".");
};
import PreviewMultipleImages from "../components/PreviewMultipleImages.vue";
import TableInventory from "@/components/productPromotion/detail/TableInventory.vue";
import TabSerial from "@/components/productPromotion/detail/serial/TableSerial.vue";
import TabCollection from "@/components/productPromotion/detail/collection/TabCollection.vue";
import TabHistory from "@/components/productPromotion/detail/history/TabHistory.vue";

export default {
  components: {
    OtherLoading,
    TabProductDetailPanel,
    TabImageProductDetailPanel,
    ProductCustomField,
    PreviewMultipleImages,
    TableInventory,
    TabSerial,
    TabCollection,
    TabHistory,
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: "",
        price: 0,
        status: 0,
        ah_4_id: "",
        article_no: "",
        article_type: "ZFIN",
        barcode: "",
        description: "",
        custom_field: {
          fields: [],
          answers: [],
          additional_images: [],
        },
        brand_id: null,
        admin_user_guid: "",
        image_url: null,
      },
      productType: [
        {
          id: "ZFIN",
          name: "ZFIN",
        },
        {
          id: "ZSAM",
          name: "ZSAM",
        },
        {
          id: "ZLPR",
          name: "ZLPR",
        },
      ],
      ah1: [],
      ah2: [],
      ah3: [],
      ah4: [],
      isLoadingAh1: false,
      isLoadingAh2: false,
      isLoadingAh3: false,
      isLoadingAh4: false,
      ah4_id: 0,
      ah1_id: 0,
      ah2_id: 0,
      ah3_id: 0,
      tabIndex: 0,
      seachKeywordAH2: "",
      seachKeywordAH3: "",
      seachKeywordAH4: "",
      id: this.$route.params.id,
      deleteImageList: [],
      isFetch: false,
      tabLoading: true,
      isGetProductField: false,
      formUpdateCollection: [],
    };
  },
  validations: {
    form: {
      name: { required },
      price: { required },
      article_no: { required, checkDots },
      article_type: { required },
      ah_4_id: { required },
      barcode: { required, checkDots },
    },
  },
  watch: {
    tabIndex(val) {
      if (val == 2 && !this.isFetch) {
        this.$refs.inventory.getInventory();
      }
      if (val == 3) {
        this.getField();
      }
    },
  },
  async created() {
    await this.getCateProductAh1("");
    if (parseInt(this.id) !== 0) {
      this.isLoading = true;
      await this.getProductDetail();
    }
  },

  methods: {
    async getField() {
      if (this.isGetProductField || this.id != 0) return;
      const { data } = await this.axios(`/ProductCustomField`);
      for (const a of data.detail) {
        if (a.field_choices) {
          let choice = a.field_choices.filter((el) => el.isDefault);
          if (a.field_type_id == 3) a.value = choice;
          else if (a.field_type_id == 2) a.value = choice.map((n) => n.id);
          else a.value = choice.map((n) => n.id)[0];
        } else a.value = "";
      }
      this.form.custom_field = { fields: data.detail, answers: [] };
      this.isGetProductField = true;
      this.$forceUpdate();
    },

    async getCateProductAh1(search) {
      this.isLoadingAh1 = true;
      let body = {
        search: search,
      };
      await this.$store.dispatch("getCategoryProductAh1", body);
      const data = this.$store.state.product.stateCategoryProductAh1;
      if (data.result === 1) {
        this.ah1 = data.detail || [];
        this.isLoadingAh1 = false;
      }
    },
    getAH4(val) {
      this.form.ah_4_id = val;
    },
    async sendForm() {
      this.$v.form.$touch();
      let error = false;
      if (this.$v.form.$error) {
        if (
          this.$v.form.name.$error ||
          this.$v.form.price.$error ||
          this.$v.form.article_no.$error ||
          this.$v.form.article_type.$error ||
          this.$v.form.ah_4_id.$error ||
          this.$v.form.barcode.$error
        ) {
          return;
        }
      }
      this.$bus.$emit("showLoading");
      this.form.admin_user_guid = this.$cookies.get(
        "back_office_admin_user_guid"
      );
      let payload = { ...this.form };

      let fields = [];
      if (this.form.custom_field.fields.length > 0) {
        let clone = JSON.parse(JSON.stringify(this.form.custom_field.fields));
        for (const a of clone) {
          if (a.value) {
            if (a.field_type_id == 3) {
              a.value = a.value.map((el) => el.id).join(",");
            }
            if (typeof a.value == "object")
              for (const b of a.value) {
                if (a.field_type_id == 2) fields.push({ ...a, value: b });
              }
            else fields.push(a);
          } else fields.push(a);
        }

        var answer = fields.map((el) => {
          let n = this.form.custom_field.answers.find(
            (a) => a.product_custom_field_id == el.id && el.value == a.value
          );
          if (n) {
            return { ...el, id: n.id, product_custom_field_id: el.id };
          } else return { ...el, id: 0, product_custom_field_id: el.id };
        });
      }

      this.isLoading = true;

      payload.custom_field = {
        product_id: 0,
        answers: answer || [],
        additional_images: payload.custom_field.additional_images
          ? payload.custom_field.additional_images.map((x, index) => ({
              image_url: x.image_url,
              sort_order: index + 1,
            }))
          : [],
      };

      if (parseInt(this.id) === 0) {
        await this.$store.dispatch("createProductPromotion", payload);
        const data = this.$store.state.product.statecreateProductPromotion;
        this.$bus.$emit("hideLoading");
        if (data.result === 1) {
          if (this.formUpdateCollection.select_collection) {
            this.formUpdateCollection.id = data.detail;
            console.log(this.formUpdateCollection.id);
            this.addCollection();
          }
          this.successAlert().then(() => this.$router.push("/product"));
        } else {
          this.errorAlert(data.message);
        }
        this.isLoading = false;
      } else {
        await this.$store.dispatch("updateProductPromotion", payload);
        const data = this.$store.state.product.stateUpdateProductPromotion;
        this.$bus.$emit("hideLoading");
        if (data.result === 1) {
          await this.addCollection();
          this.successAlert().then(() => this.$router.push("/product"));
        } else {
          this.errorAlert(data.message);
        }
        this.isLoading = false;
      }
    },
    updateCollection(collection) {
      this.formUpdateCollection = collection;
    },
    async addCollection() {
      if (this.formUpdateCollection.select_collection) {
        const res = await this.axios.post(
          `/productmenu/collection/add_product`,
          this.formUpdateCollection
        );
      }
    },
    deleteImagesList(id) {
      this.deleteImageList.push(id);
    },
    updateImageList(list) {
      this.form.custom_field.additional_images = list;
    },
    async getProductDetail() {
      await this.$store.dispatch("getProductPromotionDetail", this.id);
      const data = this.$store.state.product.stateProductPromotionDetail;
      if (data.result === 1) {
        if (data.detail.custom_field.answers.length > 0) {
          for (const a of data.detail.custom_field.answers) {
            let index = data.detail.custom_field.fields.findIndex(
              (el) => el.id == a.product_custom_field_id
            );
            let field = data.detail.custom_field.fields[index];
            if (field.field_type_id == 2) {
              if (field.value) field.value.push(a.value);
              else field.value = [a.value];
            } else if (field.field_type_id == 3) {
              if (a.value) {
                let aVal = a.value.split(",");

                let value = field.field_choices.filter((b) =>
                  aVal.includes(String(b.id))
                );

                field.value = value;
              }
            } else field.value = a.value;
          }
        } else {
          for (const a of data.detail.custom_field.fields) {
            if (a.field_choices) {
              let choice = a.field_choices.filter((el) => el.isDefault);
              if (a.field_type_id == 3) a.value = choice;
              else if (a.field_type_id == 2) a.value = choice.map((n) => n.id);
              else a.value = choice.map((n) => n.id)[0];
            } else a.value = "";
          }
        }

        this.form = data.detail;

        this.ah1_id = this.form.ah_1_id;
        this.ah2_id = this.form.ah_2_id;
        this.ah3_id = this.form.ah_3_id;
        this.ah4_id = this.form.ah_4_id;
        await this.getCategoryAH2();
        await this.getCategoryAH3();
        await this.getCategoryAH4();
        this.isLoading = false;
      }
    },
    deleteData() {},
    handleSerial(value) {
      this.form.is_serial = value;
    },
    searchAH2ByKeyword(search) {
      this.seachKeywordAH2 = search;
      this.getCategoryAH2();
    },
    searchAH2(ah1) {
      this.ah2 = [];
      this.ah3 = [];
      this.ah4 = [];
      this.ah1_id = ah1;
      this.getCategoryAH2();
    },
    async getCategoryAH2() {
      this.isLoadingAh2 = true;
      let body = {
        search: this.seachKeywordAH2,
        ah1_id: this.ah1_id,
      };
      await this.$store.dispatch("getCategoryProductAh2", body);
      const data = this.$store.state.product.stateCategoryProductAh2;
      if (data.result === 1) {
        this.ah2 = data.detail || [];
        this.isLoadingAh2 = false;
      }
    },
    searchAH3ByKeyword(val) {
      this.seachKeywordAH3 = val;
      this.getCategoryAH3();
    },
    async searchAH3(id) {
      this.ah3 = [];
      this.ah4 = [];
      this.ah1_id = id.ah1;
      this.ah2_id = id.ah2;
      this.getCategoryAH3();
    },

    async getCategoryAH3() {
      this.isLoadingAh3 = true;
      let body = {
        search: this.seachKeywordAH3,
        ah1_id: this.ah1_id,
        ah2_id: this.ah2_id,
      };
      await this.$store.dispatch("getCategoryProductAh3", body);
      const data = this.$store.state.product.stateCategoryProductAh3;
      if (data.result === 1) {
        this.ah3 = data.detail || [];
        this.isLoadingAh3 = false;
      }
    },
    searchAH4ByKeyword(val) {
      this.seachKeywordAH4 = val;
      this.getCategoryAH4();
    },
    searchAH4(id) {
      this.ah4 = [];
      this.ah1_id = id.ah1;
      this.ah2_id = id.ah2;
      this.ah3_id = id.ah3;
      this.getCategoryAH4();
    },
    async getCategoryAH4() {
      this.isLoadingAh4 = true;
      let body = {
        search: this.seachKeywordAH4,
        ah1_id: this.ah1_id,
        ah2_id: this.ah2_id,
        ah3_id: this.ah3_id,
      };
      await this.$store.dispatch("getCategoryProductAh4", body);
      const data = this.$store.state.product.stateCategoryProductAh4;
      if (data.result === 1) {
        this.ah4 = data.detail || [];
        this.isLoadingAh4 = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-template {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: transparent;
  border-radius: 0px;
  width: 30%;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-automation {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.btn-delete-automation {
  background-color: transparent;
  border: 1px solid #39424e;
  border-radius: 0px;
  width: 30%;
  color: #39424e;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}

::v-deep .nav-item {
  .nav-link.disabled {
    color: #82868c !important;
  }
}
</style>
